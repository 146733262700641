<template>
  <CRow>
    <CCol :sm="12">
        <TemperaturForm />
    </CCol>
  </CRow>
</template>

<script>
import TemperaturForm from '@/components/TemperaturForm'
export default {
  name: 'AppDatenNeu',
  components: {
    TemperaturForm
  }
}
</script>
